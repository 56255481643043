/* **************** */
/* Colors from Open-Props */
/* https://open-props.style/#colors */
/* **************** */
:root {
    /* Gray */
    --gray-0: #f8f9fa;
    --gray-1: #f1f3f5;
    --gray-2: #e9ecef;
    --gray-3: #dee2e6;
    --gray-4: #ced4da;
    --gray-5: #adb5bd;
    --gray-6: #868e96;
    --gray-7: #495057;
    --gray-8: #343a40;
    --gray-9: #212529;
    --gray-10: #16191d;
    --gray-11: #0d0f12;
    --gray-12: #030507;

    --gray-0-hsl: 210 17% 98%;
    --gray-1-hsl: 210 17% 95%;
    --gray-2-hsl: 210 16% 93%;
    --gray-3-hsl: 210 14% 89%;
    --gray-4-hsl: 210 14% 83%;
    --gray-5-hsl: 210 11% 71%;
    --gray-6-hsl: 210 7% 56%;
    --gray-7-hsl: 210 9% 31%;
    --gray-8-hsl: 210 10% 23%;
    --gray-9-hsl: 210 11% 15%;
    --gray-10-hsl: 214 14% 10%;
    --gray-11-hsl: 216 16% 6%;
    --gray-12-hsl: 210 40% 2%;

    /* Stone */
    --stone-0: #f8fafb;
    --stone-1: #f2f4f6;
    --stone-2: #ebedef;
    --stone-3: #e0e4e5;
    --stone-4: #d1d6d8;
    --stone-5: #b1b6b9;
    --stone-6: #979b9d;
    --stone-7: #7e8282;
    --stone-8: #666968;
    --stone-9: #50514f;
    --stone-10: #3a3a37;
    --stone-11: #252521;
    --stone-12: #121210;

    /* Red */    
    --red-0: #fff5f5;
    --red-1: #ffe3e3;
    --red-2: #ffc9c9;
    --red-3: #ffa8a8;
    --red-4: #ff8787;
    --red-5: #ff6b6b;
    --red-6: #fa5252;
    --red-7: #f03e3e;
    --red-8: #e03131;
    --red-9: #c92a2a;
    --red-10: #b02525;
    --red-11: #962020;
    --red-12: #7d1a1a;
    
     
    /* Purple */
    --purple-0: #f8f0fc;
    --purple-1: #f3d9fa;
    --purple-2: #eebefa;
    --purple-3: #e599f7;
    --purple-4: #da77f2;
    --purple-5: #cc5de8;
    --purple-6: #be4bdb;
    --purple-7: #ae3ec9;
    --purple-8: #9c36b5;
    --purple-9: #862e9c;
    --purple-10: #702682;
    --purple-11: #5a1e69;
    --purple-12: #44174f;
    
    /* Violet */
    --violet-0: #f3f0ff;
    --violet-1: #e5dbff;
    --violet-2: #d0bfff;
    --violet-3: #b197fc;
    --violet-4: #9775fa;
    --violet-5: #845ef7;
    --violet-6: #7950f2;
    --violet-7: #7048e8;
    --violet-8: #6741d9;
    --violet-9: #5f3dc4;
    --violet-10: #5235ab;
    --violet-11: #462d91;
    --violet-12: #3a2578;

    /* Indigo */
    --indigo-0: #edf2ff;
    --indigo-1: #dbe4ff;
    --indigo-2: #bac8ff;
    --indigo-3: #91a7ff;
    --indigo-4: #748ffc;
    --indigo-5: #5c7cfa;
    --indigo-6: #4c6ef5;
    --indigo-7: #4263eb;
    --indigo-8: #3b5bdb;
    --indigo-9: #364fc7;
    --indigo-10: #2f44ad;
    --indigo-11: #283a94;
    --indigo-12: #21307a;

    /* Blue */
    --blue-0: #e7f5ff;
    --blue-1: #d0ebff;
    --blue-2: #a5d8ff;
    --blue-3: #74c0fc;
    --blue-4: #4dabf7;
    --blue-5: #339af0;
    --blue-6: #228be6;
    --blue-7: #1c7ed6;
    --blue-8: #1971c2;
    --blue-9: #1864ab;
    --blue-10: #145591;
    --blue-11: #114678;
    --blue-12: #0d375e;

    /* Orange */
    --orange-0: #fff4e6;
    --orange-1: #ffe8cc;
    --orange-2: #ffd8a8;
    --orange-3: #ffc078;
    --orange-4: #ffa94d;
    --orange-5: #ff922b;
    --orange-6: #fd7e14;
    --orange-7: #f76707;
    --orange-8: #e8590c;
    --orange-9: #d9480f;
    --orange-10: #bf400d;
    --orange-11: #99330b;
    --orange-12: #802b09;

    
    /* Sand */
    --sand-0: #f8fafb;
    --sand-1: #e6e4dc;
    --sand-2: #d5cfbd;
    --sand-3: #c2b9a0;
    --sand-4: #aea58c;
    --sand-5: #9a9178;
    --sand-6: #867c65;
    --sand-7: #736a53;
    --sand-8: #5f5746;
    --sand-9: #4b4639;
    --sand-10: #38352d;
    --sand-11: #252521;
    --sand-12: #121210;


}
/* Global variables */

:root {
  --border-radius: 0.5rem;

  --gutter: var(--size-fluid-2);
  --padding-inline: var(--size-fluid-2);
  /* --padding-block: var(--size-fluid-5); */

  --margin-block-typography: 1rem;

  --transition-base: 250ms ease;
  --transition-movement: 200ms linear;
  --transition-fade: 200ms ease;
  --transition-bounce: 500ms cubic-bezier(0.5, 0.05, 0.2, 1.5);
  --tracking: -0.05ch;
  --tracking-s: -0.075ch;

  --card-border: 1px solid hsl(var(--brand-hue) 10% 50% / 15%);
  --card-shadow: 0 2.8px 2.2px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 3%)),
    0 6.7px 5.3px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 1%)),
    0 12.5px 10px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 2%)),
    0 22.3px 17.9px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 2%)),
    0 41.8px 33.4px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 3%)),
    0 100px 80px hsl(var(--surface-shadow) / var(--shadow-strength));

  --section-padding: var(--size-fluid-4);

}

/* Global styles */

:where(html) {
  block-size: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: var(--font-system-ui);
  line-height: var(--font-lineheight-3);
  -webkit-text-size-adjust: none;
  /* https://kilianvalkhof.com/2022/css-html/your-css-reset-needs-text-size-adjust-probably/ */

  @media (--motionOK) {
    scroll-behavior: smooth;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* make the footer 'sticky' */
main {
  flex-grow: 1;
}

:where(h1, h2, h3, h4, h5, h6) {
  font-family: var(--font-display);
  margin-block-start: 0;
  margin-block-end: var(--margin-block-typography);
}

:where(p, ul, ol, dl, h6) {
  margin-block-start: 0;
  margin-block-end: var(--margin-block-typography);
}

section {
  padding-block: var(--section-padding);
  padding-inline: var(--padding-inline);
}

hgroup > * {
  margin-block: 0;
}

hgroup > *:not(:first-child):last-child {
  color: var(--text-2);
}

svg {
  block-size: 2ex;
  inline-size: auto;
  flex: none;
}

[role="list"] {
  padding: 0;
  list-style: none;
}

/* Base interactive elements */

a {
  /* color: currentcolor; */
}

a:hover {
  text-decoration: underline solid var(--brand) 2px;
  text-underline-offset: 0.25em;
  transition: var(--transition-fade);
}

:focus {
  outline: 1px solid var(--brand);
  outline-offset: 0.3ch;
}

:target {
  scroll-margin-top: 2ex;
}

main:focus {
  outline: none;
}


/* Base selection styles that invert whatever colours are rendered */
/* ::selection {
  background: var(--color-primary);
  color: var(--color-light);
} */

.preload-transitions * {
  transition: none !important;
}

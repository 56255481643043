/* CONTAINER 
  Horizontally centered
  Customizable max width, default of 90rem or 1440px.
*/

br-container {
  display: block;
  margin-inline: auto;
  padding-block: var(--padding-block, 1.5rem);
  max-inline-size: var(--container-max-width, 85rem);
}

br-container > header > hgroup {
  text-align: center;
}

header > hgroup {
  padding-block: var(--size-fluid-2, 1rem);
}

.hero br-container {
  --container-max-width: var(--wrapper-lg);
  text-align: left;
}

br-container[data-layout="content"] {
  --container-max-width: var(--wrapper-md);
  padding-inline: var(--padding-inline);
}

br-container > br-container {
  padding-inline: 0;
}

/* ********** */
/* GRID */
/* ********** */
br-grid {
  display: grid;
  grid-template-columns: var(--grid-layout-columns,
                        repeat(var(--grid-placement, auto-fit),
                        minmax(var(--grid-min-item-size, 22rem), 1fr)));
  gap: var(--gutter);
  max-inline-size: var(--grid-max-width, 85rem);
  align-items: var(--grid-alignment, start);
  margin-inline: auto;
  background-color: var(--grid-bg-color);
}

br-grid > :is(img, picture, video, figure) {
  justify-self: center;
}

.posts br-grid {
  --grid-placement: auto-fill;
}

br-grid[data-layout="50-50"] {
  --grid-min-item-size: clamp(16rem, 50vw, 26rem);
  --grid-placement: auto-fit;
}

br-grid[data-layout="vertical"] {
  --grid-layout-columns: 1fr;
  --grid-max-width: var(--wrapper-md);
}


/* ********** */
/* PAGINATION */
/* ********** */

br-container#pagination {
  padding-block: 0;
}

ul.pagination {
  display: flex;
  justify-content: center;
  gap: var(--size-fluid-2);
  padding: 0;
  margin: 0;
}

ul.pagination>li {
  list-style: none;
  margin: 0;
}

ul.pagination > li.pagination-left {
  grid-area: left;
  justify-self: right;
}

ul.pagination > li.pagination-right {
  grid-area: right;
  justify-self: left;
}


/* TODO: add `lite-vimeo` */
lite-youtube, lite-vimeo {
  aspect-ratio: 16 / 9;
}

/* CARD */
/* TODO: style `header` and `footer`, make it a flex container */
/* REFACTOR: add padding to direct child elements, like `header` and `footer` */
article {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  /* padding: var(--padding-inline); */
  /* padding-block: var(--padding-inline); */
  border-radius: var(--border-radius);
  background-color: var(--surface-2);
  /* margin-block-end: var(--size-fluid-1); */
  /* OP Rad Shadow: */
  border: var(--card-border);
  box-shadow: var(--card-shadow);
}

article > * {
  padding-inline: var(--padding-inline);
}

article> :first-child {
  padding-block-start: var(--padding-inline);
}

article> :last-child {
  padding-block-end: var(--padding-inline);
}

article > header, article > article-body, article > footer {
  padding-block: var(--padding-inline);
}

article > :last-child {
  margin-block-end: 0;
}

article > header {
  border-bottom: var(--border-size-1) solid var(--surface-4);
}

article > footer {
  border-top: var(--border-size-1) solid var(--surface-4);
}

article-body {
  flex-grow: 1;
  background-color: var(--surface-1);
}

section-label {
  font-size: var(--font-size-fluid-0);
  text-transform: uppercase;
  font-weight: var(--font-weight-7);
  color: var(--brand);
}

/* Remove top margin on H1 in Hero section */
hgroup.flow > section-label + h1 {
  --flow-space: 0;
}

svg-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--size-fluid-2);
  margin-block-end: var(--margin-block-typography);
}

svg-header h3 {
  margin-block-end: 0;
}

svg-header img, svg-header svg {
  /* height: 40px;
  width: 40px; */
  height: var(--size-fluid-3); /* 1.5rem to 2rem */
  width: var(--size-fluid-3);
  /* margin-inline-end: var(--size-fluid-1); */
  /* margin-block-start: -(var(--margin-block-typography)); */
}

br-grid picture {
  /* padding: var(--size-fluid-2); */
  /* max-width: 500px; */
}

/* Place images in the center of the grid cell */
br-container br-grid > picture {
  justify-self: center;
  align-self: center;
}

section header > hgroup > p {
  margin-inline: auto;
  max-inline-size: var(--size-content-3);
}


form {
  display: grid;
  gap: var(--size-fluid-2);
  max-inline-size: var(--size-content-3);
}

label {
  display: block;
}

/* ************************ */
/* TODO: Fix the left margin conflict with PicoCSS */
/* ************************ */

body > footer {
    background: var(--surface-3-dark);
    color: var(--text-1-dim);
    font-size: var(--font-size-fluid-0);
    padding-inline: var(--padding-inline);
}

body > footer nav > ul > li {
    padding: 0;
}

/* The last footer styling */
body footer > footer {
    display: flex;
    justify-content: center;
}

body footer > footer ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

body footer >footer li {
    margin-right: 1rem;
}

body footer >footer li:last-child {
    margin-right: 0;
}

body footer > footer p {
    margin: 0;
}


body > footer nav:not(#social) {
    display: flex;
    flex-direction: column;
}

body > footer nav > ul {
    align-items: start; 
    display: flex;
    flex-direction: column;
    margin-inline-start: 0;
}

body > footer br-grid #social > ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--size-fluid-1);
}

body > footer a {
    color: var(--text-2);
}

body > footer #social a:hover {
    color: var(--brand);
}

body > footer footer {
    font-size: var(--font-size-fluid-0);
}
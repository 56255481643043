/* from Open Props: */
/* https: //codepen.io/argyleink/pen/powMQgx?editors=1100 */

/* Base Button Styles */
.button, button {
  display: inline-flex;
  white-space: nowrap;
  font-size: var(--font-size-fluid-1);
  font-weight: var(--font-weight-7);
  /* padding-inline: var(--size-6); */
  /* padding-block: var(--size-3); */
  /* padding-block: .75ch; */
  padding-block: var(--size-relative-2);
  padding-inline: var(--size-relative-6);
  color: var(--brand);
  border: var(--border-size-2) solid var(--brand);
  background-color: transparent;
  border-radius: var(--radius-2);
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover,
.button:hover,
button:focus,
.button:focus {
  cursor: pointer;
  color: var(--surface-2);
  background-color: var(--brand);
  text-shadow: 0 1px 0 var(--surface-1);
}

.button:active:not(:focus-visible) {
  position: relative;
  /* inset-block-start: 2px; Uncomment if needed */
}

/* Dark Theme (auto-switching with user preference) */
@media (prefers-color-scheme: dark) {
  button {
    /* border-color: var(--surface-4); */
    color: var(--brand);
  }

  button:hover,
  button:focus {
    background-color: var(--brand);
    /* border-color: var(--brand); */
    color: var(--gray-9);
  }
}

/* Dim Theme (manual via data-theme="dim") */
:where([data-theme="dim"]) button {
  /* border-color: var(--surface-4); */
  color: var(--brand);
}

:where([data-theme="dim"]) button:hover,
:where([data-theme="dim"]) button:focus {
  background-color: var(--brand);
  /* border-color: var(--brand); */
  /* color: var(--surface-1); */
  color: var(--gray-9);
}

/* Dark Theme (manual via data-theme="dark") */
:where([data-theme="dark"]) button {
  /* border-color: var(--surface-3); */
  color: var(--brand);
}

:where([data-theme="dark"]) button:hover,
:where([data-theme="dark"]) button:focus {
  background-color: var(--brand);
  color: var(--surface-3);
}

/* Reduced Motion Preferences */
@media (prefers-reduced-motion: no-preference) {
  button:focus {
    transition: outline-offset .25s ease;
  }

  button:focus:not(:active) {
    outline-offset: 5px;
  }
}
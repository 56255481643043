/* Theme variables */
html {
  /* light */
  --brand-light: var(--orange-6);
  --text-1-light: var(--gray-8);
  --text-2-light: var(--gray-7);
  --surface-1-light: var(--gray-0);
  --surface-2-light: var(--gray-1);
  --surface-3-light: var(--gray-2);
  --surface-4-light: var(--gray-3);
  /* --surface-1-light: var(--sand-0);
  --surface-2-light: var(--sand-1);
  --surface-3-light: var(--sand-2);
  --surface-4-light: var(--sand-3); */
  --surface-shadow-light: var(--gray-8-hsl);
  --shadow-strength-light: 2%;

  /* dark */
  --brand-dark: var(--orange-3);
  --text-1-dark: var(--gray-3);
  --text-2-dark: var(--gray-5);
  --surface-1-dark: var(--gray-12);
  --surface-2-dark: var(--gray-11);
  --surface-3-dark: var(--gray-10);
  --surface-4-dark: var(--gray-9);
  --surface-shadow-dark: var(--gray-12-hsl);
  --shadow-strength-dark: 80%;

  /* dim */
  --brand-dim: var(--orange-4);
  --text-1-dim: var(--gray-3);
  --text-2-dim: var(--gray-4);
  --surface-1-dim: var(--gray-8);
  --surface-2-dim: var(--gray-7);
  --surface-3-dim: var(--gray-6);
  --surface-4-dim: var(--gray-5);
  /* --text-1-dim: var(--stone-3);
  --text-2-dim: var(--stone-4);
  --surface-1-dim: var(--stone-10);
  --surface-2-dim: var(--stone-9);
  --surface-3-dim: var(--stone-7);
  --surface-4-dim: var(--stone-6); */
  --surface-shadow-dim: var(--gray-9-hsl);
  --shadow-strength-dim: 20%;

}

:root {
  /* Default: light theme */
  color-scheme: light;

  --brand: var(--brand-light);
  --text-1: var(--text-1-light);
  --text-2: var(--text-2-light);
  --surface-1: var(--surface-1-light);
  --surface-2: var(--surface-2-light);
  --surface-3: var(--surface-3-light);
  --surface-4: var(--surface-4-light);
  --surface-shadow: var(--surface-shadow-light);
  --shadow-strength: var(--shadow-strength-light);
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Switch to dim (dark) theme */
    color-scheme: dark;

    --brand: var(--brand-dim);
    --text-1: var(--text-1-dim);
    --text-2: var(--text-2-dim);
    --surface-1: var(--surface-1-dim);
    --surface-2: var(--surface-2-dim);
    --surface-3: var(--surface-3-dim);
    --surface-4: var(--surface-4-dim);
    --surface-shadow: var(--surface-shadow-dim);
    --shadow-strength: var(--shadow-strength-dim);

    --link: var(--indigo-3);
    --link-visited: var(--purple-3);
  }
}

/* Dark Theme applied via data-theme="dark" */
:where([data-theme="dark"]) {
  --brand: var(--brand-dark);
  --text-1: var(--text-1-dark);
  --text-2: var(--text-2-dark);
  --surface-1: var(--surface-1-dark);
  --surface-2: var(--surface-2-dark);
  --surface-3: var(--surface-3-dark);
  --surface-4: var(--surface-4-dark);
  --surface-shadow: var(--surface-shadow-dark);
  --shadow-strength: var(--shadow-strength-dark);
}

/* Dim Theme applied via data-theme="dim" */
:where([data-theme="dim"]) {
  --brand: var(--brand-dim);
  --text-1: var(--text-1-dim);
  --text-2: var(--text-2-dim);
  --surface-1: var(--surface-1-dim);
  --surface-2: var(--surface-2-dim);
  --surface-3: var(--surface-3-dim);
  --surface-4: var(--surface-4-dim);
  --surface-shadow: var(--surface-shadow-dim);
  --shadow-strength: var(--shadow-strength-dim);
}


:where(html) {
  accent-color: var(--brand, var(--link));
  caret-color: var(--brand, var(--link));
  color: var(--text-1);
  background-color: var(--surface-1);
}

/* Styling example for text, links, and background within any section */
:where([data-theme="dark"], [data-theme="dim"]) {
  background-color: var(--surface-1);
  color: var(--text-1);
}

:where(a[href]) {
  color: var(--brand, var(--link));

  &:where(:visited) {
    color: var(--link-visited);
  }
}

:focus-visible {
  outline-color: var(--brand, var(--link));
}

html {
  background-color: var(--surface-1);
  color: var(--text-1);
  accent-color: var(--brand);
}

:where(h1) {
  color: var(--brand);
}

:where(h1, h2, h3, h4, h5, h6) {
  /* color: var(--brand); */
}

p {
  /* max-inline-size: var(--size-content-1); */
  /* font-size: var(--font-size-4); */
  line-height: var(--font-lineheight-3);
}

.rad-shadow {
  border: 1px solid hsl(var(--brand-hue) 10% 50% / 15%);
  box-shadow:
    0 2.8px 2.2px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 3%)),
    0 6.7px 5.3px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 1%)),
    0 12.5px 10px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 2%)),
    0 22.3px 17.9px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 2%)),
    0 41.8px 33.4px hsl(var(--surface-shadow) / calc(var(--shadow-strength) + 3%)),
    0 100px 80px hsl(var(--surface-shadow) / var(--shadow-strength));
}
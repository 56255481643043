/* **************** */
/* Open-Props borders */
/* https://open-props.style/#borders */
/* **************** */

:where(html) {
        --border-size-1: 1px;
        --border-size-2: 2px;
        --border-size-3: 5px;
        --border-size-4: 10px;
        --border-size-5: 25px;
        --radius-1: 2px;
        --radius-2: 5px;
        --radius-3: 1rem;
        --radius-4: 2rem;
        --radius-5: 4rem;
        --radius-6: 8rem;
        --radius-drawn-1: 255px 15px 225px 15px / 15px 225px 15px 255px;
        --radius-drawn-2: 125px 10px 20px 185px / 25px 205px 205px 25px;
        --radius-drawn-3: 15px 255px 15px 225px / 225px 15px 255px 15px;
        --radius-drawn-4: 15px 25px 155px 25px / 225px 150px 25px 115px;
        --radius-drawn-5: 250px 25px 15px 20px / 15px 80px 105px 115px;
        --radius-drawn-6: 28px 100px 20px 15px / 150px 30px 205px 225px;
        --radius-round: 1e5px;
        --radius-blob-1: 30% 70% 70% 30% / 53% 30% 70% 47%;
        --radius-blob-2: 53% 47% 34% 66% / 63% 46% 54% 37%;
        --radius-blob-3: 37% 63% 56% 44% / 49% 56% 44% 51%;
        --radius-blob-4: 63% 37% 37% 63% / 43% 37% 63% 57%;
        --radius-blob-5: 49% 51% 48% 52% / 57% 44% 56% 43%;
        --radius-conditional-1: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-1));
        --radius-conditional-2: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-2));
        --radius-conditional-3: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-3));
        --radius-conditional-4: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-4));
        --radius-conditional-5: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-5));
        --radius-conditional-6: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-6));
}

  /* more info about conditional border radius: */
  /* https://ishadeed.com/article/conditional-border-radius/ */
:root {

    /* From Modern Font Stacks: */
    /* https: //github.com/system-fonts/modern-font-stacks */
    --font-system-ui: system-ui, sans-serif;
    --font-transitional: Charter, Bitstream Charter, Sitka Text, Cambria, serif;
    --font-monospace-code: Dank Mono, Operator Mono, Inconsolata, Fira Mono, ui-monospace, SF Mono, Monaco, Droid Sans Mono, Source Code Pro, Cascadia Code, Menlo, Consolas, DejaVu Sans Mono, monospace;

    /* --font-display: RedHatDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
    --font-mono: "RobotoMono", Consolas, Menlo, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    --font-geometric-humanist: Avenir, Montserrat, Corbel, URW Gothic, source-sans-pro, sans-serif;
    
    --font-sans: var(--font-system-ui);
    --font-serif: var(--font-transitional);
    --font-mono: var(--font-monospace-code);
    --font-base: var(--font-sans);
    --font-display: var(--font-transitional);

    --font-size-00: .5rem;
    --font-size-0: .75rem;
    --font-size-1: 1rem;
    --font-size-2: 1.1rem;
    --font-size-3: 1.25rem;
    --font-size-4: 1.5rem;
    --font-size-5: 2rem;
    --font-size-6: 2.5rem;
    --font-size-7: 3rem;
    --font-size-8: 3.5rem;

    /* Fluid typography sizes */
    --font-size-fluid-0: clamp(.75rem, 1.5vw, 1rem);
    --font-size-fluid-1: clamp(1rem, 2vw, 1.5rem);
    --font-size-fluid-2: clamp(1.5rem, 3vw, 2.5rem);
    --font-size-fluid-3: clamp(2rem, 4vw, 3.5rem);

    --font-lineheight-00: .95;
    --font-lineheight-0: 1.1;
    --font-lineheight-1: 1.25;
    --font-lineheight-2: 1.375;
    --font-lineheight-3: 1.5;
    --font-lineheight-4: 1.75;
    --font-lineheight-5: 2;

    --font-weight-1: 100;
    --font-weight-2: 200;
    --font-weight-3: 300;
    --font-weight-4: 400;
    --font-weight-5: 500;
    --font-weight-6: 600;
    --font-weight-7: 700;
    --font-weight-8: 800;
    --font-weight-9: 900;

}
/* **************** */
/* Open-Props sizes */
/* https://github.com/argyleink/open-props/blob/main/src/props.sizes.css */
/* **************** */

:root {    
    --size-000: -.5rem;
    --size-00: -.25rem;
    --size-1: .25rem;
    --size-2: .5rem;
    --size-3: 1rem;
    --size-4: 1.25rem;
    --size-5: 1.5rem;
    --size-6: 1.75rem;
    --size-7: 2rem;
    --size-8: 3rem;
    --size-9: 4rem;
    --size-10: 5rem;
    --size-11: 7.5rem;
    --size-12: 10rem;
    --size-13: 15rem;
    --size-14: 20rem;
    --size-15: 30rem;
    
    --size-fluid-1: clamp(.5rem, 1vw, 1rem);
    --size-fluid-2: clamp(1rem, 2vw, 1.5rem);
    --size-fluid-3: clamp(1.5rem, 3vw, 2rem);
    --size-fluid-4: clamp(2rem, 4vw, 3rem);
    --size-fluid-5: clamp(4rem, 5vw, 5rem);
    --size-fluid-6: clamp(5rem, 7vw, 7.5rem);
    --size-fluid-7: clamp(7.5rem, 10vw, 10rem);
    --size-fluid-8: clamp(10rem, 20vw, 15rem);
    --size-fluid-9: clamp(15rem, 30vw, 20rem);
    --size-fluid-10: clamp(20rem, 40vw, 30rem);

    --size-content-1: 20ch;
    --size-content-2: 45ch;
    --size-content-3: 60ch;
    --size-content-4: 75ch;

    --size-header-1: 20ch;
    --size-header-2: 25ch;
    --size-header-3: 35ch;

    --size-xxs: 240px;
    --size-xs: 360px;
    --size-sm: 480px;
    --size-md: 768px;
    --size-lg: 1024px;
    --size-xl: 1440px;
    --size-xxl: 1920px;

    /* wrapper widths */
    --wrapper-md: 55rem;    /* 880px */
    --wrapper-lg: 65rem;    /* 1040px */
    --wrapper-xl: 75rem;    /* 1200px */
    --wrapper-xxl: 85rem;    /* 1360px */

    --size-relative-000: -.5ch;
    --size-relative-00: -.25ch;
    --size-relative-1: .25ch;
    --size-relative-2: .5ch;
    --size-relative-3: 1ch;
    --size-relative-4: 1.25ch;
    --size-relative-5: 1.5ch;
    --size-relative-6: 1.75ch;
    --size-relative-7: 2ch;
    --size-relative-8: 3ch;
    --size-relative-9: 4ch;
    --size-relative-10: 5ch;
    --size-relative-11: 7.5ch;
    --size-relative-12: 10ch;
    --size-relative-13: 15ch;
    --size-relative-14: 20ch;
    --size-relative-15: 30ch;

}
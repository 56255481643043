@import 'normalize.css';

/* @import "animations.css"; */
@import "borders.css";
/* @import "buttons.css"; */
@import "buttons-simpler.css";
@import "colors.css";
/* @import "gradients.css"; */
@import "shadows.css";
@import "sizes.css";
@import "typography.css";